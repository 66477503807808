.spinner {
  display: inline-block;

  &--rotate {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #8a2be2ff;
    animation: spin 1s ease-in-out infinite;

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }

  &--jump {
    display: flex;
    justify-content: space-between;
    gap: 6px;

    div {
      width: 9px;
      height: 9px;
      background-color: #8a2be2ff;
      border-radius: 50%;
      animation: jump 0.6s infinite alternate;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }

    @keyframes jump {
      to {
        transform: translateY(-50%);
      }
    }
  }

  &--wave {
    display: flex;
    justify-content: space-between;
    gap: 6px;

    div {
      width: 9px;
      height: 9px;
      background-color: #8a2be2ff;
      border-radius: 50%;
      animation: wave 0.6s infinite;

      &:nth-child(1) {
        animation-delay: 0.1s;
      }

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.3s;
      }
    }

    @keyframes wave {
      0%,
      60%,
      100% {
        transform: initial;
      }
      30% {
        transform: translateY(-15px);
      }
    }
  }
}
